<template>
    <div class="cuenta-figuras">
        <nav-bar sizetitle="sm" nivel="1"></nav-bar>
        <header-type-game title="Percepción"></header-type-game>
        <tutorial-game 
        v-if="tutorialStatus" 
        audio="tuto" 
        video="https://res.cloudinary.com/juegos-cramer/video/upload/v1617836232/juegos-cramer/tutoriales/nivel1/percepcion/contar_figuras_mz11og.mp4"
        text="¿Cuántas figuras hay? Debe contarlas todas. Marque el número correcto"></tutorial-game>
        <div v-if="!tutorialStatus&&gameRuning" >
            <clock-down minuto="1" segundo="30" @setgameruning="setGameRuning()" ></clock-down>
            <div class="cf__box-game-container">
                <div class="cf__box-game">
                    <div class="box-game__figures">
                        <div v-for="item in figuras" 
                            :key="item.id" 
                            :class="['figure-container', 'pos'+item.pos]"
                            :style="'transform:rotate('+item.rotacion+'deg);'"
                        >
                            <svg-figure :figure="item.figura" :color="item.color" size="100"></svg-figure>
                        </div>
                    </div>
                    <div class="box-game__alternatives">
                        <div>
                        <button 
                            v-for="(item,index) in alternativas"
                            :key="item.id"
                            class="alternative"
                            @click="getKeyAns(item.clave,index)"
                        >
                            <span>{{item.numero}}</span>
                            <span v-if="visibleAnswer&&(index==index_answerSelected)" :class="['cf__check-answer', answerSelected]"></span>
                        </button>
                        </div>
                        <div :class="capaProtectora"></div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="!gameRuning">
            <results-game nombrejuego="cuenta figuras" nivel="1" inteligencia="percepcion"></results-game>
        </div>
        
    </div>
</template>
<script>

import SvgFigure from './SvgFigure'
import NavBar from '@/components/NavBar.vue'
import HeaderTypeGame from '@/components/HeaderTypeGame'
import TutorialGame from '@/components/TutorialGame'
import ClockDown from '@/components/ClockDown'
import ResultsGame from '@/components/ResultsGame'
import {mapState,mapMutations} from 'vuex'
export default {
    components: {
        SvgFigure,
        NavBar,
        HeaderTypeGame,
        TutorialGame,
        ClockDown,
        ResultsGame
    },
    data:()=>({
        puntos:0,
        figuras:[],
        alternativas:[],
        gameRuning:true,
        answerSelected:'',
        visibleAnswer:false,
        index_answerSelected:0,
        capaProtectora:''
    }),
    computed:{
        ...mapState(['tutorialStatus'])
    },
    methods:{
        ...mapMutations(['changeMenuBarBottom','changeScoreGame','changeScoreDescription','changeTutorialStatus']),
        setGameRuning(){
            this.gameRuning = false
            if(this.puntos<0)
            {
                this.changeScoreGame(0)
                this.changeScoreDescription('insuficiente')
            }
            else{
                this.changeScoreGame(this.puntos)
                if(this.puntos<=200){
                this.changeScoreDescription('insuficiente')
                }
                else if(this.puntos<=400){
                    this.changeScoreDescription('escasa')
                }
                else if(this.puntos<=600){
                    this.changeScoreDescription('suficiente')
                }
                else if(this.puntos<=800){
                    this.changeScoreDescription('buena')
                }
                else{
                    this.changeScoreDescription('optima')
                }
            }
        },
        getRandom(min, max) {
            return Math.floor(Math.random() * (max - min+1) ) + min;
        },
        getArrayRandom(min,max,cantidad){
            //let total = max-min;
            let res = [];
            while(!(res.length==cantidad))
            {
                let aux = this.getRandom(min,max);
                let a = res.find((e)=>e==aux)
                if(!a){
                    res.push(aux)
                }
            }
            return res;
        },
        getKeyAns(key,index){
            this.visibleAnswer = true
            this.index_answerSelected = index
            this.capaProtectora = 'cf__capa-protectora'
            if(key){
                let a = new Audio(require('../../../assets/juegos/audios/acierto.mp3'))
                a.play()
                this.puntos = this.puntos + 60;
                this.answerSelected = 'cf__correct-answer'
            }
            else{
                let a = new Audio(require('../../../assets/juegos/audios/fallo.mp3'))
                a.play()
                this.puntos = this.puntos - 30;
                this.answerSelected = 'cf__wrong-answer'
            }

            window.setTimeout(()=>{
                this.capaProtectora=''
                this.gameGenerator()
            },1000)
        },
        createColor(){
            let red = this.getRandom(10,200);
            let green = this.getRandom(10,200);
            let blue = this.getRandom(10,200);
            let alpha = this.getRandom(30,100);
            return 'rgba('+red+','+green+','+blue+','+alpha/100+')'
        },
        getArrayResRandom(min,max,nro){
            //let total = max-min;
            let res = [];
            res.push(nro)
            while(!(res.length==3))
            {
                let aux = this.getRandom(min,max);
                let a = res.find((e)=>e==aux)
                if(!a&&a!=nro){
                    res.push(aux)
                }
            }
            
            return res;
        },
        gameGenerator(){
            this.visibleAnswer = false
            this.figuras = []
            let total_figuras = 0;
            total_figuras = this.getRandom(3,11)
            let figuras = ['circle','triangle','square','pentagon','heptagon','star']
            let posiciones = this.getArrayRandom(1,36,total_figuras)

            posiciones.forEach(e=>{
                this.figuras.push({
                    color:this.createColor(),
                    figura:figuras[this.getRandom(0,5)],
                    pos:e,
                    rotacion:this.getRandom(0,360)
                })
            })
            this.alternativas = []
            let lim_inf = total_figuras -3;
            let lim_sup = total_figuras +3;
            if(lim_inf<=0){lim_inf=1}
            let pos_alternativa = this.getArrayResRandom(lim_inf,lim_sup,total_figuras)
            this.alternativas.push({
                    pos:this.getRandom(1,100),
                    clave:true,
                    numero:pos_alternativa[0]
                })
            for(let i=1;i<3;i++)
            {
                this.alternativas.push({
                    pos:this.getRandom(1,100),
                    clave:false,
                    numero:pos_alternativa[i]
                })
            }

            this.alternativas.sort((a,b)=>a.pos-b.pos)

            //console.log(total_figuras)
            //console.log(pos_alternativa)
        }
    },
    mounted(){
        this.changeMenuBarBottom(false)
        this.gameGenerator()
        this.changeTutorialStatus(true)
        this.gameRuning = true
    }
}
</script>
<style>
.cuenta-figuras{
    width: 100%;
    height: 100vh;
    background-color: #1F0275;
}
.cf__box-game-container{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top:2em;
}
.cf__box-game{
    background-color: #EEEFE4;
    width: 95%;
    height: 50vh;
    border-radius: 10px;

}
.box-game__figures{
    display: grid;
    width: 100%;
    height: 80%;
    align-items: center;
    grid-template-columns: repeat(10,10%);
    grid-template-rows: repeat(10,10%);
}
.figure-container{
    width: 6em;
    height: 6em;
}

.box-game__alternatives{
    width: 100%;
    height: 20%;
    position: relative;
    
}
.box-game__alternatives div{
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: space-evenly;
    align-items: center;
}
.cf__capa-protectora{
    position: absolute;
    bottom: 0px;
    height: 3em;
    width: 100%;
    background-color: red;
    opacity: 0;
}
.alternative{
    width: 3em;
    height: 3em;
    background-color:#7B5656;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    position: relative;
    cursor: pointer;
}
.alternative span{
    font-size: 2em;
    color:#e4dada;
    font-weight: bold;
}
.cf__check-answer{
    position: absolute;
    width:1em;
    height: 1em;
    top:-8px;
    right: -8px;
    background-size: 100% 100%;
}
.cf__wrong-answer{
    background-image: url('../../../assets/juegos/comunes/cancelar.svg');
}
.cf__correct-answer{
    background-image: url('../../../assets/juegos/comunes/comprobado.svg');
}

@media (min-width: 900px) and (orientation: landscape) {
    .cf__box-game{
        width: 60%;
        }   
    }
.pos1{
    grid-column: 2 / 2;
    grid-row: 3;
}
.pos2{
    grid-column: 3;
    grid-row: 3;
}
.pos3{
    grid-column: 4;
    grid-row: 3;
}
.pos4{
    grid-column: 5;
    grid-row: 3;
}
.pos5{
    grid-column: 6;
    grid-row: 3;
}
.pos6{
    grid-column: 7;
    grid-row: 3;
}
.pos7{
    grid-column: 2;
    grid-row: 4;
}
.pos8{
    grid-column: 3;
    grid-row: 4;
}
.pos9{
    grid-column: 4;
    grid-row: 4;
}
.pos10{
    grid-column: 5;
    grid-row: 4;
}
.pos11{
    grid-column: 6;
    grid-row: 4;
}
.pos12{
    grid-column: 7;
    grid-row: 4;
}
.pos13{
    grid-column: 2;
    grid-row: 5;
}
.pos14{
    grid-column: 3;
    grid-row: 5;
}
.pos15{
    grid-column: 4;
    grid-row: 5;
}
.pos16{
    grid-column: 5;
    grid-row: 5;
}
.pos17{
    grid-column: 6;
    grid-row: 5;
}
.pos18{
    grid-column: 7;
    grid-row: 5;
}
.pos19{
    grid-column: 2;
    grid-row: 6;
}
.pos20{
    grid-column: 3;
    grid-row: 6;
}
.pos21{
    grid-column: 4;
    grid-row: 6;
}
.pos22{
    grid-column: 5;
    grid-row: 6;
}
.pos23{
    grid-column: 6;
    grid-row: 6;
}
.pos24{
    grid-column: 7;
    grid-row: 6;
}
.pos25{
    grid-column: 2;
    grid-row: 7;
}
.pos26{
    grid-column: 3;
    grid-row: 7;
}
.pos27{
    grid-column: 4;
    grid-row: 7;
}
.pos28{
    grid-column: 5;
    grid-row: 7;
}
.pos29{
    grid-column: 6;
    grid-row: 7;
}
.pos30{
    grid-column: 7;
    grid-row: 7;
}
.pos31{
    grid-column: 2;
    grid-row: 8;
}
.pos32{
    grid-column: 3;
    grid-row: 8;
}
.pos33{
    grid-column: 4;
    grid-row: 8;
}
.pos34{
    grid-column: 5;
    grid-row: 8;
}
.pos35{
    grid-column: 6;
    grid-row: 8;
}
.pos36{
    grid-column: 8;
    grid-row: 8;
}

</style>