<template>
    <div class="svg-figure">
        <svg v-if="figure=='square'" xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 0 98 92">
            <defs>
               
            </defs>
            <g class="a" :style="'stroke:'+color">
                <rect class="b" width="98" height="92"/>
                <rect class="c" x="1.5" y="1.5" width="95" height="89"/>
            </g>
        </svg>
        <svg v-if="figure=='circle'" xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 0 91 90">
            <defs>
                
            </defs>
            <g class="a" :style="'stroke:'+color">
                <ellipse class="b" cx="45.5" cy="45" rx="45.5" ry="45"/>
                <ellipse class="c" cx="45.5" cy="45" rx="44" ry="43.5"/>
            </g>
        </svg>
        <svg v-if="figure=='triangle'" xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 0 107 99">
            <defs>
                
            </defs>
            <g class="a-triangle">
                <path class="b-triangle" d="M53.5,0,107,99H0Z"/>
                <path class="c-triangle" :style="'fill:'+color" d="M 53.5 6.310142517089844 L 5.031242370605469 96 L 101.9687576293945 96 L 53.5 6.310142517089844 M 53.5 0 L 107 99 L 0 99 L 53.5 0 Z"/>
            </g>
        </svg>
        <svg v-if="figure=='star'" xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 0 106 106">
            <defs>
               
            </defs>
            <g class="a-star">
                <path class="b-star" d="M53,0,66.25,39.42,106,40.488,74.439,65.92,85.756,106,53,82.3,20.244,106,31.561,65.92,0,40.488,39.75,39.42Z"/>
                <path class="c-star" :style="'fill:'+color" d="M 53 9.415962219238281 L 42.59366607666016 40.3756103515625 L 41.92584991455078 42.36236190795898 L 39.83061981201172 42.41869735717773 L 8.231170654296875 43.26821136474609 L 33.44336700439453 63.58376312255859 L 34.98676300048828 64.82740783691406 L 34.44816589355469 66.73496246337891 L 25.47599029541016 98.51120758056641 L 51.24131774902344 79.86723327636719 L 53 78.59462738037109 L 54.75868225097656 79.86723327636719 L 80.52399444580078 98.51119995117188 L 71.55181884765625 66.73496246337891 L 71.01321411132813 64.82740783691406 L 72.55661773681641 63.58376312255859 L 97.76882171630859 43.26819610595703 L 66.16938781738281 42.41869735717773 L 64.07415008544922 42.36236190795898 L 63.40633392333984 40.3756103515625 L 53 9.415962219238281 M 53 -7.62939453125e-06 L 66.25 39.41977691650391 L 106 40.48839569091797 L 74.43893432617188 65.91976165771484 L 85.75578308105469 106 L 53 82.29766082763672 L 20.24420166015625 106 L 31.56105041503906 65.91976165771484 L 0 40.48841094970703 L 39.75 39.41977691650391 L 53 -7.62939453125e-06 Z"/>
            </g>
        </svg>
        <svg v-if="figure=='pentagon'" xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 0 97 91">
            <defs>
            </defs>
            <g class="a-penta">
                <path class="b-penta" d="M48.5,0,97,34.759,78.475,91H18.525L0,34.759Z"/>
                <path class="c-penta" :style="'fill:'+color" d="M 48.49998474121094 3.690879821777344 L 3.538887023925781 35.91355514526367 L 20.69574737548828 88 L 76.30427551269531 88 L 93.46111297607422 35.91355895996094 L 48.49998474121094 3.690879821777344 M 48.49998474121094 0 L 97 34.7589225769043 L 78.47466278076172 91 L 18.52536773681641 91 L 0 34.7589225769043 L 48.49998474121094 0 Z"/>
            </g>
        </svg>
        <svg v-if="figure=='heptagon'" xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 0 117 111">
            <defs>
                
            </defs>
            <g class="a-heptagon">
                <path class="b-heptagon" d="M58.5,0l46.913,21.985L117,71.385,84.535,111H32.465L0,71.385l11.587-49.4Z"/>
                <path class="c-heptagon" :style="'fill:'+color" d="M 58.49998474121094 3.313087463378906 L 14.17550659179688 24.08479309082031 L 3.258819580078125 70.62816619873047 L 33.88521575927734 108 L 83.11479187011719 107.9999847412109 L 113.7411880493164 70.62815093994141 L 102.824462890625 24.08477783203125 L 58.49998474121094 3.313087463378906 M 58.49998474121094 0 L 105.4133453369141 21.98490905761719 L 117 71.38454437255859 L 84.53497314453125 110.9999847412109 L 32.46504211425781 111 L 7.62939453125e-06 71.38456726074219 L 11.58662414550781 21.98492431640625 L 58.49998474121094 0 Z"/>
            </g>
        </svg>
    </div>
</template>
<script>
export default {
    name:'SvgFigure',
    props:['figure','color','size'],
    data:()=>({
        
    })
}
</script>
 <style>
 .svg-figure{
     width: 100%;
     display: flex;
     justify-content: center;
     align-items: center;
 }
    .a,.c{
        fill:none;
        }
    .a{
        
        stroke-width:3px;
    }
    .b{
        stroke:none;
        }
    .a-triangle{fill:none;}.b-triangle,.c-triangle{stroke:none;}
    .a-star{fill:none;}.b-star,.c-star{stroke:none;}
    .a-penta{fill:none;}.b-penta,.c-penta{stroke:none;}
    .a-heptagon{fill:none;}.b-heptagon,.c-heptagon{stroke:none;}
</style>